@font-face {
  font-family: "SourceHanSansCN-Bold";
  src: url("../font/SourceHanSansCN-Bold.otf");
}

@font-face {
  font-family: "SourceHanSansCN-ExtraLight";
  src: url("../font/SourceHanSansCN-ExtraLight.otf");
}

@font-face {
  font-family: "SourceHanSansCN-Heavy";
  src: url("../font/SourceHanSansCN-Heavy.otf");
}

@font-face {
  font-family: "SourceHanSansCN-Light";
  src: url("../font/SourceHanSansCN-Light.otf");
}

@font-face {
  font-family: "SourceHanSansCN-Medium";
  src: url("../font/SourceHanSansCN-Medium.otf");
}

@font-face {
  font-family: "SourceHanSansCN-Normal";
  src: url("../font/SourceHanSansCN-Normal.otf");
}

@font-face {
  font-family: "SourceHanSansCN-Regular";
  src: url("../font/SourceHanSansCN-Regular.otf");
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: SourceHanSansCN-Regular, sans-serif;
}

ul {
  margin: 0;
  padding: 0;

  > li {
    list-style: none;
  }
}

a,
a:focus {
  color: inherit;
  text-decoration: none;
}

i{
  cursor: pointer;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(88, 107, 156, 0.4);


  &:hover {
    background-color: rgba(109, 132, 193, 0.8);
  }
}

::-webkit-scrollbar-corner {
  display: none;
}