



























































.rightFlex {
  position: fixed;
  top: 243px;
  right: 0;
  margin-top: -100px;
  z-index: 9999;

  ul li {
    width: 50px;
    height: 50px;
    // color: #fff;
    line-height: 50px;
    right: 0;
    position: absolute;
    background: #000096;

    .ico {
      width: 50px;
      height: 50px;
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      img {
        width: 18px;
        height: 18px;
      }
    }

    .right-fix-box {
      position: absolute;
      right: 60px;
      top: 0;
      background-color: #fff;
      white-space: nowrap;
      display: none;
      height: 127px;
    }

    &:hover .right-fix-box {
      display: block;
    }

    .wxqrcode-box img {
      width: 127px;
      height: 127px;
    }
    .wxqrcode-box:after {
      content: "";
      position: absolute;
      right: -13px;
      top: 15px;
      border-top: solid 7px transparent;
      border-right: solid 7px transparent;
      border-bottom: solid 7px transparent;
      border-left: solid 7px #fff;
    }

    .tel {
      width: 220px;
      height: 50px;
      padding: 0 10px 0 10px;
      font-size: 18px;
      line-height: 50px;
    }
    .tel:after {
      content: "";
      position: absolute;
      right: -13px;
      top: 15px;
      border-top: solid 7px transparent;
      border-right: solid 7px transparent;
      border-bottom: solid 7px transparent;
      border-left: solid 7px #fff;
    }

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 51px;
    }

    &:nth-child(3) {
      top: 102px;
    }

    &:nth-child(4) {
      top: 150px;
    }
  }
}
@media (max-width: 767px) {
  .rightFlex {
    display: none;
  }
}
