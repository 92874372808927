




































































































section {
  width: 100%;
  background-color: #404143;
  padding: 50px 0;
  clear: both;

  & a:hover {
    color: #fff !important;
  }

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;

    > a:first-child {
      flex: 0 0 216px;
      height: 35px;
      margin-top: 50px;
      background: url("../assets/img/logo.png") no-repeat center;
      cursor: pointer;
    }

    > div:nth-child(2) {
      margin: 0 auto;

      > ul {
        display: flex;
        justify-content: center;

        > li {
          padding: 0 35px;

          &:not(&:last-of-type) {
            border-right: 1px #989898 dotted;
          }

          > ul {
            > li:first-child {
              color: #fff;
              font-size: 16px;
              margin-bottom: 20px;
            }

            > li {
              color: #989898;
              font-size: 13px;
              margin: 10px 0;

              > a {
                transition: color 0.2s linear;
                white-space: nowrap;

                &:hover {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    > div:nth-child(3) {
      > div:nth-child(1) {
        color: #ffffff;
        font-size: 16px;
        margin-bottom: 18px;
        text-align: center;
      }

      > div:nth-child(2) {
        width: 130px;
        height: 130px;
        background: #fff url("../assets/img/wxQrcode.svg") no-repeat center;
        align-self: center;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    > a:first-child {
      width: 40px;
      height: 40px;
      background: url("../assets/img/icon_gov.png") no-repeat center;
      background-size: cover;
      margin-right: 5px;
    }

    > div:first-of-type {
      margin-right: 20px;
    }

    > div {
      margin: 0 5px;
      color: #989898;
      line-height: 40px;
      font-size: 13px;

      > a {
        transition: color 0.2s linear;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    > div {
      margin: 0 5px;
      color: #989898;
      font-size: 13px;

      > a {
        transition: color 0.2s linear;

        &:hover {
          color: #fff;
        }
      }

      &:last-child {
        display: none;
      }
    }
  }
}
@media (max-width: 767px) {
  section {
    >div:nth-child(1){
      width: 100%;
      >a{
        display: none;
      }
      >div:nth-child(2){
        display: none !important;
      }
      >div:nth-child(3){
        margin: 0 auto;
      }
    }
    >div:nth-child(2){
      flex-wrap: wrap;
      align-items: center;
      div{
        line-height:13px;
      }
    }
    >div:nth-child(3){
      flex-wrap: wrap;
    }
  }
}
