







































































































































































































.header-wrapper {
  width: 100%;
  position: fixed;
  z-index: 999;
}
nav {
  height: 86px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-top: 4px;
  > .nav-logo {
    width: 216px;
    height: 35px;
    background: url("../assets/img/logo.png") no-repeat center;
  }

  > .nav-bar {
    margin-left: 36px;
    height: 90px;
    > ul {
      > li:first-child {
        > ul {
          > li:nth-child(1) {
            > ul {
              > li:first-child {
                height: 80px;
                background: url("../assets/img/quipment_cur.svg") no-repeat;
                background-size: 30%;
                position: relative;

                > a {
                  position: absolute;
                  bottom: 0;
                  display: inline-block;
                  width: 100%;
                  height: 80px;
                  line-height: 120px;
                  z-index: 100;
                }
              }
            }
          }

          > li:nth-child(2) {
            > ul {
              > li:first-child {
                height: 80px;
                background: url("../assets/img/vehicle_cur.png") no-repeat ;
                background-size: 30%;
                position: relative;

                > a {
                  position: absolute;
                  bottom: 0;
                  display: inline-block;
                  width: 100%;
                  height: 80px;
                  line-height: 120px;
                }
              }
            }
          }

          > li:nth-child(3) {
            > ul {
              > li:first-child {
                height: 80px;
                background: url("../assets/img/module_cur.png") no-repeat 10% 0;
                background-size: 15%;
                position: relative;

                > a {
                  position: absolute;
                  bottom: 0;
                  display: inline-block;
                  width: 100%;
                  height: 80px;
                  line-height: 120px;
                }
              }
            }
          }

          > li:nth-child(4) {
            > ul {
              > li:first-child {
                height: 80px;
                background: url("../assets/img/monitor_cur.png") no-repeat 10% 0;
                background-size: 15%;
                position: relative;

                > a {
                  position: absolute;
                  bottom: 0;
                  display: inline-block;
                  width: 100%;
                  height: 80px;
                  line-height: 120px;
                }
              }
            }
          }

          > li:nth-child(5) {
            > ul {
              > li:first-child {
                height: 80px;
                background: url("../assets/img/cloudPlatform_cur.png") no-repeat;
                background-size: 30%;
                position: relative;

                > a {
                  position: absolute;
                  bottom: 0;
                  display: inline-block;
                  width: 100%;
                  height: 80px;
                  line-height: 120px;
                }
              }
            }
          }
        }
      }

      > li {
        float: left;
        color: #fff;
        font-size: 18px;
        line-height: 90px;

        > div {
          padding: 0 18px;
          position: relative;

          > a {
            display: inline-block;
            line-height: 90px;
          }

          &:hover {
            & + ul {
              display: flex;
            }

            &::after {
              content: '';
              position: absolute;
              left: 50px;
              bottom: 0;
              display: inline-block;
              border-top: solid 6px transparent;
              border-right: solid 6px transparent;
              border-bottom: solid 6px rgba(255, 255, 255, 0.8);
              border-left: solid 6px transparent;
            }
          }
        }

        > ul {
          display: none;
          position: absolute;
          top: 90px;
          left: 20%;
          width: 60%;
          color: #000000;
          background-color: rgb(255, 255, 255, 0.8);
          justify-content: center;
          box-shadow: 0px 3px 5px #888888;

          &:hover {
            display: flex;
          }

          > li {
            padding: 10px 20px;
            flex: 1;
            text-align: left;
            overflow: hidden;

            &:not(&:last-of-type) {
              border-right: 1px #404143 dashed;
            }

            > ul {
              > li {
                font-size: 14px;
                line-height: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              > li:first-child {
                color: #000096;
                font-size: 16px;
                font-weight: bold;
                line-height: 50px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  nav {
    display: block;
    height: 50px;
    width: 100%;
    >a {
      position: absolute;
      top: 7.5px;
      left: 10px;
    }
    >.nav-bar {
      display: none;
    }
    >.navbar-toggle{
      display: block !important;
      position: absolute;
      margin: 11px 30px 8px 0px;
      padding: 0;
      border: 0 ;
      right: -7px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      background: none;
      >.icon-bar{
        display: block;
        padding: 0;
        border-radius: 1px;
        height: 5px;
        background: #666 ;
        width: 28px;
      }
      >.icon-bar:nth-child(2),:nth-child(3){
        margin: 4px 0 0;
      }
    }
    >.navbar-collapse{
      position: relative;
      top: 50px;
      padding: 0px;
      margin: 0px;
      box-shadow: 0px 3px 5px #888888;
      //transition: height 1s ease 0s;
      >ul{
        padding: 0;
        margin: 0;
        width: 100%;
        >li{
          height: 50px;
          background: #FFF;
          //opacity: 0.95;
          line-height: 50px;
          a{
            color: #777;
            font-size: 18px;
            line-height: 50px;
            display: block;
            height: 50px;
            padding: 0;
            width: 100% !important;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          a:hover{
            color: rgb(51, 51, 51);
            background-color: rgb(153,153,153);
          }
        }
        >div{
          height: 50px;
          background: #FFF;
          //opacity: 0.95;
          line-height: 50px;
          text-align: center;
          position:relative;
          >input{
            width: 40%;
            height: 30px;
            color: #FFF;
            font-family: "Microsoft YaHei";
            font-size: 12px;
            border: 1px solid #cbcbcb;
            margin: 10px 30%;
            float: left;
            color: #333;
            border-radius: 3px;
            padding-left: 10px;
            outline: none;
            line-height: 30px;
          }
          >button {
            position: absolute;
            border: 0;
            right: 0;
            background: transparent url("../assets/img/ser1.png") no-repeat 0 0;
            width: 40px;
            height: 40px;
            position: absolute;
            right: 10px;
            top: 5px;
            cursor: pointer;
          }
        }
        >div:hover{
          background: rgb(153,153,153);
        }
      }
    }
  }
}
